
// Libraries
import * as React from 'react'

// Services
import ApiService from "../../../services/api"

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import DuoSection from "../../../components/duoSection"
import AdminVenueViewRow from "../../../components/admin/venues/adminVenueViewRow"
import InputFactory from "../../../components/inputFactory"
import Button from "../../../components/button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminOrganisationsNewPage extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const { inputValues, inputErrors, isSubmitting } = this.state

		const addressErrors = inputErrors ? {
			street: inputErrors['address.street'],
			street_2: inputErrors['address.street_2'],
			state: inputErrors['address.state'],
			postal_code: inputErrors['address.postal_code'],
			city: inputErrors['address.city'],
			region: inputErrors['address.region']
		} : {}

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav pageAdminForm>
				<Seo title="New Organisation" />
				<DuoSection adminForm>
					<div>
						<h1>New Organisation</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form admin-form--organisations">
							<div className="admin-form__form">
								<form onSubmit={ this.handleSubmit } encType="multipart/form-data">
									<div className="admin-form__field-group">
										<h2>Information</h2>
										<AdminVenueViewRow
											label="Organisation Name*"
											value={
												<InputFactory
													type="text"
													name="name"
													placeholder="Name"
													value={ inputValues?.name }
													error={ inputErrors?.name }
													onChange={ this.handleInputChange }
												/>
											} />

										<AdminVenueViewRow
											label="Address*"
											value={
												<InputFactory
													type="address"
													name="address"
													placeholder="Address"
													value={ inputValues?.address }
													error={ addressErrors }
													onChange={ this.handleInputChange }
												/>
											} />

										<AdminVenueViewRow
											checkbox
											label=""
											value={
												<InputFactory
													type="checkbox"
													name="outside_england"
													label={<>
														Is your organisation registered outside of England?<br/>
														<small>If checked, your organisation will be subject to a manual
															eligibility process as per our <a href="/terms" target="_blank">terms of service</a></small>
													</>}
													checked={ inputValues?.outside_england }
													value={ inputValues?.outside_england }
													error={ inputErrors?.outside_england }
													onChange={ this.handleInputChange }
													checkboxEndeavour
												/>
											} />

										{ inputValues?.outside_england !== true &&
											<AdminVenueViewRow
												label="CQC Location ID*"
												value={
												<InputFactory
													type="text"
													name="cqc_location_id"
													placeholder="ID"
													value={ inputValues?.cqc_location_id }
													error={ inputErrors?.cqc_location_id }
													onChange={ this.handleInputChange }
												/>
											} />
										}

										<AdminVenueViewRow
											label="Contact Name*"
											value={
												<InputFactory
													type="text"
													name="contact_name"
													placeholder="Name"
													value={ inputValues?.contact_name }
													error={ inputErrors?.contact_name }
													onChange={ this.handleInputChange }
												/>
											} />

										<AdminVenueViewRow
											label="Contact Telephone Number*"
											value={
												<InputFactory
													type="text"
													placeholder="Telephone"
													name="contact_tel"
													value={ inputValues?.contact_tel }
													error={ inputErrors?.contact_tel }
													onChange={ this.handleInputChange }
												/>
											} />

										<AdminVenueViewRow
											label="Contact Email*"
											value={
												<InputFactory
													type="email"
													name="contact_email"
													placeholder="Email"
													value={ inputValues?.contact_email }
													error={ inputErrors?.contact_email }
													onChange={ this.handleInputChange }
												/>
											} />
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/organisations" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={ isSubmitting } colorEndeavour>Add Organisation</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	handleInputChange = (value, name, scope) => {
		const { inputValues } = this.state

		if (name === 'outside_england') {
			delete inputValues['cqc_location_id']
		}

		if (value !== undefined) {
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const { inputValues } = this.state
		const apiService = new ApiService()
		const { addNotification } = this.context

		// Set default address values 
		if (inputValues.address) {
			inputValues.address.country_code = 'GB'
			inputValues.address.is_primary = true
			inputValues.address.is_billing = true
			inputValues.address.is_shipping = true
		}

		// Convert inputValues into a form data object
		const body = new FormData()
		Object.keys(inputValues).forEach(key => {
			if (key === 'address') {
				Object.keys(inputValues[key]).forEach(subKey => {
					body.append(`address[${subKey}]`, inputValues[key][subKey])
				})
			}
			else {
				body.append(key, inputValues[key])
			}
		})

		// Submit venue data
		await apiService.post(`organisations`, { body: body }, true)
			.then(async (response) => {
				if (response.success) {
					addNotification('New Organisation Created', 'success')
					this.props.navigate('/admin/organisations')
				}
				else {
					this.setState({
						inputErrors: response.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminOrganisationsNewPage
